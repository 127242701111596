body {
    overflow-x: hidden;
  }

.Container{
    position:relative;
    margin-left: 25%;
}
.Text{
    font-family: 'Times New Roman', Times, serif;
    color:white;
    font-weight:bold;
    font-size:x-large;
}


td {
    font-family: 'Times New Roman', Times, serif;
    max-width: 24ch;
    word-wrap:break-word;
    font-size: large;
  }
  br {
    height: 10px; /* Adjust the height to change the line break size */
  }

  .textContainer{
    position:relative;
    margin-left: 5%;
}

.testings{
    font-family: 'Times New Roman', Times, serif;
    max-width: 60ch;
    word-wrap:break-word;
    font-size: medium;
    font-family: 'Times New Roman', Times, serif;
    color:#ebebeb;
    font-weight:400;
  }


  .imgs1{
    
    max-width: 500px;
    -webkit-user-drag: none;
    border-style: solid;
    border-radius:8px;
    border-width: 1px;
    border-color: #acc2d9;
}

.imgs10{
    
  max-width: 200px;
  -webkit-user-drag: none;
  border-style: solid;
  border-radius:8px;
  border-width: 1px;
  border-color: #acc2d9;
}