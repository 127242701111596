
.upperBar {
    position:relative;
    margin-top:8%;
    margin-left: 25%;
    justify-content:space-between;
    padding-right:25%;
    
}

.upperBar b{
padding-right:21.7%;
}

.links2{
    font-family: 'Times New Roman', Times, serif;
    color:#acc2d9;
    font-size: xx-large;
    text-decoration: none;
    text-indent: 50px;
    word-spacing: 30px;
    max-width: 200px;
}
.links2:hover {
    color:#cff3da;
}

.star{
    -webkit-user-drag: none;
    max-width:200px;
    transform: translate(0, 40%); /* Adjust the vertical position to move it 5% upwards */
}
