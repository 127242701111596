.spacing{
    height: 10vh;
}
.spacing2{
    height: 1vh;
}
.map-box {
    width: 750px;
    height: 300px; 
    border: 2px solid #ccc;
    border-radius: 8px;
    overflow: hidden; 
  }
  .map-container {
    display:grid;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }

.map-text{
    font-family:'Times New Roman', Times, serif;
    font-weight: bold;
    font-size:large;
    color:white;
}