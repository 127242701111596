.mernimg{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 500px;
    -webkit-user-drag: none;
    border-style: solid;
    border-radius:8px;
    border-width: 1px;
    border-color: #acc2d9;
}
.spacing3{
    height: 5vh;
}

.imgs1{
    
    max-width: 500px;
    -webkit-user-drag: none;
    border-style: solid;
    border-radius:8px;
    border-width: 1px;
    border-color: #acc2d9;
}