.lowerBar {
    text-align: center;
    position:relative;
    bottom:10%;
    width:100%;
    margin-bottom:5%;
  }

.dark{
    font-family: "Times New Roman";
    color:white;
    font-size: medium;
    text-decoration: none;
    
}

.links{
    color:#acc2d9;
    font-size: medium;
    text-decoration: none;
    text-indent: 50px;
    word-spacing: 30px;
}
.links:hover {
    color:#cff3da;
}