.img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-width: 175px;
    -webkit-user-drag: none;
}
.spacing3{
    height: 5vh;
}