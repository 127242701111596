body {
    overflow-x: hidden;
  }

.Container{
    position:relative;
    margin-left: 25%;
}
.Text{
    font-family: 'Times New Roman', Times, serif;
    color:white;
    font-weight:bold;
    font-size:x-large;
}


td {
    font-family: 'Times New Roman', Times, serif;
    max-width: 24ch;
    word-wrap:break-word;
    font-size: large;
  }
  br {
    height: 10px; /* Adjust the height to change the line break size */
  }

  .textContainer{
    position:relative;
    margin-left: 5%;
}

.testings{
    font-family: 'Times New Roman', Times, serif;
    max-width: 60ch;
    word-wrap:break-word;
    font-size: medium;
    font-family: 'Times New Roman', Times, serif;
    color:#ebebeb;
    font-weight:400;
  }


