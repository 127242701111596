body {
    overflow-x: hidden;
  }

.projContainer{
    position:relative;
    margin-left: 25%;
}
.projText{
    font-family: 'Times New Roman', Times, serif;
    color:white;
    font-weight:bold;
    font-size:x-large;
}
.links3{
    color:#acc2d9;
    font-size: medium;
    text-decoration: none;
    font-weight:bolder;

}
.links3:hover {
    color:#cff3da;
}

.text5{
    font-family: 'Times New Roman', Times, serif;
    color:white;
    font-weight:bold;
    font-size:large;
}


td {
    font-family: 'Times New Roman', Times, serif;
    max-width: 24ch;
    word-wrap:break-word;
    font-size: large;
  }
  br {
    height: 10px; /* Adjust the height to change the line break size */
  }

  .textContainer{
    position:relative;
    margin-left: 5%;
}

.testings{
    font-family: 'Times New Roman', Times, serif;
    max-width: 60ch;
    word-wrap:break-word;
    font-size: medium;
    font-family: 'Times New Roman', Times, serif;
    color:#ebebeb;
    font-weight:400;
  }


.general{
    display:flex;
}

.kevin_img{
    position: relative;
    margin-left:84%;
}